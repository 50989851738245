
import { defineComponent } from 'vue'
import * as Components from '@/components'
import * as code from './code'

export default defineComponent({
  components: { ...Components },
  name: 'PageLineChart',
  setup () {
    return { code }
  }
})
