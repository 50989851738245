
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'TabBlock',
  components: {},
  props: {
    tabs: {
      type: Array,
      default: () => []
    }
  },
  setup (_, { slots }) {
    const index = ref(0)

    return { index }
  }
})
