
import { computed, defineComponent, inject, ref } from 'vue'
import { useRoute } from 'vue-router'
import Logo from '../Logo/index.vue'

export default defineComponent({
  name: 'NavBar',
  components: { Logo },
  setup () {
    const route = useRoute()
    const pageName = computed(() => route.name || 'home')
    const showSidebar = inject('sidebar', ref(false))
    return { route, pageName, showSidebar }
  }
})
