
export default {
  name: 'PropItems',
  props: {
    name: { type: String },
    types: { type: Array },
    odd: { type: Boolean, default: false },
    link: { type: String }
  }
}
