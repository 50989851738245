
import { defineComponent } from 'vue'
import { Navbar, ChartBlock, AreaChart, Sidebar } from '@/components'
import * as code from './code'

export default defineComponent({
  components: { Navbar, ChartBlock, AreaChart, Sidebar },
  name: 'PageHome',
  setup () {
    return { code }
  }
})
