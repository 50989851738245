
import { defineComponent } from 'vue'

interface Prop {
  name: string
  types: string[]
  description: string
}

export default defineComponent({
  name: 'PropsBlock',
  props: {
    props: {
      type: Array as () => Prop[],
      default: () => []
    }
  }
})
