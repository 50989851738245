import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrismEditor = _resolveComponent("PrismEditor")!
  const _component_TabBlock = _resolveComponent("TabBlock")!

  return (_openBlock(), _createBlock(_component_TabBlock, {
    class: "my-3",
    tabs: [
      { label: 'Chart', icon: 'show_chart' },
      { label: 'Code', icon: 'code' },
      { label: 'Data', icon: 'receipt_long  ' }
    ]
  }, {
    default: _withCtx(({ selected }) => [
      (selected === 0)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 })
        : _createCommentVNode("", true),
      (selected === 1)
        ? (_openBlock(), _createBlock(_component_PrismEditor, {
            key: 1,
            code: _ctx.chartCode,
            readonly: true
          }, null, 8, ["code"]))
        : _createCommentVNode("", true),
      (selected === 2)
        ? (_openBlock(), _createBlock(_component_PrismEditor, {
            key: 2,
            code: _ctx.dataCode,
            readonly: true,
            style: { minHeight: '420px' }
          }, null, 8, ["code"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}