import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Pie = _resolveComponent("Pie")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_Chart = _resolveComponent("Chart")!
  const _component_Responsive = _resolveComponent("Responsive")!

  return (_openBlock(), _createBlock(_component_Responsive, { class: "w-full" }, {
    main: _withCtx(({ width }) => [
      _createVNode(_component_Chart, {
        direction: "circular",
        size: { width, height: 400 },
        data: _ctx.data,
        margin: {
          left: Math.round((width - 360)/2),
          top: 20,
          right: 0,
          bottom: 20
        },
        axis: _ctx.axis,
        config: { controlHover: false }
      }, {
        layers: _withCtx(() => [
          _createVNode(_component_Pie, {
            dataKeys: ['name', 'pl'],
            "pie-style": { innerRadius: 100, padAngle: 0.05 }
          }, null, 8, ["pie-style"])
        ]),
        widgets: _withCtx(() => [
          _createVNode(_component_Tooltip, {
            config: {
              name: { },
              avg: { hide: true},
              pl: { label: 'value' },
              inc: { hide: true }
            },
            hideLine: ""
          })
        ]),
        _: 2
      }, 1032, ["size", "data", "margin", "axis"])
    ]),
    _: 1
  }))
}