
import { defineComponent, ref } from 'vue'
import { Responsive, Chart, Grid, Area, Group, Tooltip } from 'vue3-charts'
import { plByMonth } from '@/data'

export default defineComponent({
  name: 'AreaChart',
  components: { Responsive, Chart, Grid, Area, Group, Tooltip },
  setup () {
    const data = ref(plByMonth)
    const direction = ref('horizontal')
    const marker = ref(0)
    const margin = ref({
      left: 10,
      top: 20,
      right: 20,
      bottom: 10
    })

    const axis = ref({
      primary: {
        type: 'band'
      },
      secondary: {
        domain: ['dataMin', 'dataMax + 200'],
        type: 'linear',
        ticks: 8
      }
    })

    return { data, direction, margin, axis, marker }
  }
})
