import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Line = _resolveComponent("Line")!
  const _component_LabelsLayer = _resolveComponent("LabelsLayer")!
  const _component_Chart = _resolveComponent("Chart")!
  const _component_Responsive = _resolveComponent("Responsive")!

  return (_openBlock(), _createBlock(_component_Responsive, { class: "w-full" }, {
    main: _withCtx(({ width }) => [
      _createVNode(_component_Chart, {
        size: { width, height: 420 },
        data: _ctx.data,
        margin: _ctx.margin,
        direction: _ctx.direction,
        axis: _ctx.axis
      }, {
        layers: _withCtx(() => [
          _createVNode(_component_Line, { dataKeys: ['name', 'pl'] }),
          (_ctx.showLabels)
            ? (_openBlock(), _createBlock(_component_LabelsLayer, {
                key: 0,
                dataKeys: ['name', 'pl']
              }))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["size", "data", "margin", "direction", "axis"])
    ]),
    _: 1
  }))
}