
import { defineComponent } from 'vue'
import { PrismEditor } from '@/components/core'

export default defineComponent({
  name: 'CodeBlock',
  components: { PrismEditor },
  props: {
    code: {
      type: String,
      default: ''
    },
    lang: {
      type: String,
      default: 'markup'
    },
    oneLine: { type: Boolean, default: false }
  }
})
