import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "shadow-sm rounded overflow-hidden border border-gray-200" }
const _hoisted_2 = { class: "flex border-b border-gray-200" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "material-icons-outlined mr-2" }
const _hoisted_5 = { class: "flex flex-co" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["flex items-center px-6 py-3 cursor-pointer hover:text-green-600", { ' border-b-2 border-green-600 text-green-600': _ctx.index === i }]),
          key: i,
          onClick: ($event: any) => (_ctx.index = i)
        }, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(tab.icon), 1),
          _createTextVNode(" " + _toDisplayString(tab.label), 1)
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "default", { selected: _ctx.index })
    ])
  ]))
}