
import { defineComponent } from 'vue'
import * as Components from '@/components'

export default defineComponent({
  components: { ...Components },
  name: 'PageCharts',
  setup () {
    return {}
  }
})
