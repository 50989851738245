
import { defineComponent } from 'vue'
import { PrismEditor } from '@/components/core'
import TabBlock from '../TabBlock/index.vue'

export default defineComponent({
  name: 'ChartBlock',
  components: { PrismEditor, TabBlock },
  props: {
    chartCode: {
      type: String,
      default: ''
    },
    dataCode: {
      type: String,
      default: ''
    }
  }
})
