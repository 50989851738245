
import { defineComponent, onMounted } from 'vue'
import { Navbar, Sidebar } from '@/components'

export default defineComponent({
  components: { Navbar, Sidebar },
  name: 'PageDocs',
  setup () {
    onMounted(() => {
      console.log('work')
    })
  }
})
