<template>
  <div class="text-red-500">Hello</div>
</template>

<script>
export default {
  name: 'HelloWorld'
}
</script>

<style></style>
