
import { defineComponent, inject, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import data from './data'

export default defineComponent({
  name: 'SideBar',
  components: {},
  setup () {
    const items = ref(data)
    const route = useRoute()
    const router = useRouter()
    const showSidebar = inject('sidebar', ref(true))

    function goTo (to: string) {
      router.push(to)
      showSidebar.value = false
    }

    return { items, route, showSidebar, goTo }
  }
})
