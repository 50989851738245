
import { defineComponent, ref } from 'vue'
import { Responsive, Chart, Pie, Tooltip } from 'vue3-charts'
import { plByMonth } from '@/data'

export default defineComponent({
  name: 'PieChart',
  components: { Responsive, Chart, Tooltip, Pie },
  setup () {
    const data = ref(plByMonth)
    const axis = ref({ primary: { hide: true }, secondary: { hide: true } })

    return { data, axis }
  }
})
