import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b64f326"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "shadow-sm rounded overflow-hidden border border-gray-200 my-2 code-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrismEditor = _resolveComponent("PrismEditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PrismEditor, {
      code: _ctx.code,
      readonly: true,
      lang: _ctx.lang,
      oneLine: _ctx.oneLine
    }, null, 8, ["code", "lang", "oneLine"])
  ]))
}