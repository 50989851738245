
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LogoImg',
  props: {
    width: {
      default: 'auto'
    },
    height: {
      default: '25px'
    }
  }
})
