import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Line = _resolveComponent("Line")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_Chart = _resolveComponent("Chart")!
  const _component_Responsive = _resolveComponent("Responsive")!

  return (_openBlock(), _createBlock(_component_Responsive, { class: "w-full" }, {
    main: _withCtx(({ width }) => [
      _createVNode(_component_Chart, {
        size: { width, height: 420 },
        data: _ctx.data,
        margin: _ctx.margin,
        direction: _ctx.direction,
        axis: _ctx.axis
      }, {
        layers: _withCtx(() => [
          _createVNode(_component_Grid, { strokeDasharray: "2,2" }),
          _createVNode(_component_Line, { dataKeys: ['name', 'pl'] }),
          _createVNode(_component_Line, {
            dataKeys: ['name', 'avg'],
            lineStyle: { stroke: 'red' },
            type: "step"
          })
        ]),
        widgets: _withCtx(() => [
          _createVNode(_component_Tooltip, {
            borderColor: "#48CAE4",
            config: {
              name: { hide: true },
              pl: { color: '#0077b6' },
              avg: { label: 'averange', color: 'red' },
              inc: { hide: true }
            }
          })
        ]),
        _: 2
      }, 1032, ["size", "data", "margin", "direction", "axis"])
    ]),
    _: 1
  }))
}