
import { defineComponent, ref } from 'vue'
import { Responsive, Chart, Grid, Line } from 'vue3-charts'
import { plByMonth } from '@/data'

export default defineComponent({
  name: 'LineChart',
  components: { Responsive, Chart, Grid, Line },
  setup () {
    const data = ref(plByMonth)
    const direction = ref('horizontal')
    const margin = ref({
      left: 10,
      top: 20,
      right: 20,
      bottom: 10
    })

    return { data, direction, margin }
  }
})
