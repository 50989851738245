import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76298c38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-wrap items-start" }
const _hoisted_2 = {
  key: 1,
  class: "m-1 code"
}
const _hoisted_3 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["props-row", { 'bg-odd': $props.odd }])
  }, [
    _createElementVNode("div", null, _toDisplayString($props.name), 1),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.types, (type) => {
        return (_openBlock(), _createElementBlock("div", { key: type }, [
          ($props.link)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: "block m-1 code",
                to: `/docs/types#${type.toLowerCase()}`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(type), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(type), 1))
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}