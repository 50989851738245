import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "layer-labels" }
const _hoisted_2 = ["x", "y"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("g", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.points, (p, i) => {
      return (_openBlock(), _createElementBlock("text", {
        key: i,
        x: p.x,
        y: p.y - 10,
        "text-anchor": "middle",
        "font-size": "12",
        fill: "#8884d8"
      }, _toDisplayString(p.props.data[_ctx.dataKeys[1]]), 9, _hoisted_2))
    }), 128))
  ]))
}